// extracted by mini-css-extract-plugin
export var content_separator = "index-module--content_separator--3M16i";
export var layout_container = "index-module--layout_container--xy3fU";
export var left_side = "index-module--left_side--3bBpr";
export var content = "index-module--content--3lyt1";
export var left_wrapper = "index-module--left_wrapper--10Qfw";
export var left_side_image_container = "index-module--left_side_image_container--q6JYr";
export var desktopOnly = "index-module--desktopOnly--mtDcZ";
export var mobileOnly = "index-module--mobileOnly--2cFT6";
export var layout_header = "index-module--layout_header--1NnKm";
export var layout_title_link = "index-module--layout_title_link--3buvV";
export var layout_title = "index-module--layout_title--Lg-_b";
export var panel_container = "index-module--panel_container--1-qHl";
export var panel_content = "index-module--panel_content--2Ek-S";
export var pill_container = "index-module--pill_container--2NfM7";
export var tag_title = "index-module--tag_title--1a4hd";
export var progress = "index-module--progress--f3iK0";
export var avatar = "index-module--avatar--2yJNp";
export var right_side = "index-module--right_side--3hX0q";